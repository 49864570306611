import styled from 'styled-components';
import { atMinWidth } from '../../atoms/breakpoints';
import { colors } from '../../atoms/colors';
import shadow from '../../atoms/shadows';
import { font } from '../../atoms/typography';

export const HeadingWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  gap: 12px;
  max-width: 100%;

  ${atMinWidth.lg`
    max-width: 570px;
  `}
`;

export const Heading = styled.h2`
  ${font('display', 'md', '700')}
  color: ${colors.gray[900]};

  ${atMinWidth.sm`
    ${font('display', 'lg', '700')}
  `}

  ${atMinWidth.md`
    ${font('display', 'xl', '700')}
  `}
`;

export const Subhead = styled.p`
  ${font('text', 'md', '400')}
  color: ${colors.gray[600]};

  ${atMinWidth.sm`
    ${font('text', 'lg', '400')}
  `}

  ${atMinWidth.md`
    ${font('text', 'xl', '400')}
  `}
`;

export const IntegrationsContainer = styled.div`
  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 56px;

    input {
      max-width: 269px;
      @media (max-width: 991px) {
        display: none;
      }
      &#search-box {
        font-size: 18px;
        padding-left: 44px;
        padding-top: 12px;
        background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cpath%20fill%3D%22%237B848F%22%20d%3D%22M508.5%20481.6l-129-129c-2.3-2.3-5.3-3.5-8.5-3.5h-10.3C395%20312%20416%20262.5%20416%20208%20416%2093.1%20322.9%200%20208%200S0%2093.1%200%20208s93.1%20208%20208%20208c54.5%200%20104-21%20141.1-55.2V371c0%203.2%201.3%206.2%203.5%208.5l129%20129c4.7%204.7%2012.3%204.7%2017%200l9.9-9.9c4.7-4.7%204.7-12.3%200-17zM208%20384c-97.3%200-176-78.7-176-176S110.7%2032%20208%2032s176%2078.7%20176%20176-78.7%20176-176%20176z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
        background-repeat: no-repeat;
        background-position: 16px center;
        background-size: 16px;
        &:hover {
          border-color: #0015ca;
        }

        &:focus {
          box-shadow: none;
          border-color: var(--primary-color);
          background-color: #f9fbfe;
        }
      }
    }
  }

  .filter {
    max-width: 300px;
    position: sticky;
    top: 130px;
    z-index: 1000;
    margin-bottom: 64px;

    @media (max-width: 991px) {
      max-width: 100%;
    }

    .accordion {
      button {
        font-style: normal;
        font-weight: 700;
        font-size: 22.5px;
        line-height: 110%;
        align-items: center;
        text-align: left;
        letter-spacing: -0.27px;
        color: #1d2030;
        background: transparent;
        border: none;
        margin-bottom: 36px;
        width: 100%;

        .icon {
          font-size: 18px;
          right: 0;
          position: absolute;
        }
      }
    }

    .category {
      padding: 16px 16px 12px 16px;
      border-radius: 2px;
      margin: 8px 0;

      p {
        ${font('text', 'sm', '400')}
        margin-bottom: 0px;
        color: rgb(77, 80, 97);
        margin-bottom: 0;
      }

      &.active {
        background: #e9f2ff;
        p {
          font-weight: 700;
          color: #1c3eff;
        }
      }

      &:hover {
        background: #e9f2ff;

        p {
          font-weight: 700;
          color: #1c3eff;
        }
      }
    }
  }

  .content {
    border-bottom: 1px solid #e4e7ec;
    padding-bottom: 32px;
    margin-bottom: 64px;

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }

    .state-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;

      @media (max-width: 991px) {
        padding: 0 16px;
        flex-direction: column;
      }

      p {
        margin-bottom: 0;
      }

      .view-text {
        ${font('display', 'xs', '500')}
        color: #343643;
      }

      .counter {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.16px;
        color: #6f7283;
      }
    }

    .cat-title {
      ${font('display', 'md', '600')}
      display: flex;
      align-items: center;
      color: #1d2030;
      margin-bottom: 32px;
    }

    .integrations-wrapper {
      .integration-card-wrapper {
        cursor: pointer;
        text-decoration: none;
      }

      .card-wrapper {
        margin-bottom: 32px;
      }

      .integration-card {
        max-width: 370px;
        border-radius: 12px;
        transition: all 0.3s ease 0s;
        height: 100%;
        ${shadow('xl')}
        display: flex;
        flex-direction: column;
        height: 100%;

        @media (max-width: 768px) {
          max-width: 100%;
        }

        &:hover {
          .logo-wrapper {
            img {
              transform: scale(1);
            }
          }
        }

        .logo-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 290px;
          background-color: ${colors.base.white};
          border-radius: 12px 12px 0 0;
          position: relative;
          overflow: hidden;

          .gatsby-image-wrapper {
            display: block;
            position: static;
          }

          img {
            position: absolute;
            inset: 0;
            object-fit: contain !important;
            object-position: center;
            transform-origin: center;
            transition: transform 300ms ease-in-out 0s !important;
            transform: scale(0.9);
          }
        }

        .details {
          padding: 24px;
          min-height: 200px;
          background: var(--white);
          border-radius: 0 0 12px 12px;
          border-top: 0.25px solid ${colors.gray[200]};
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
          gap: 44px;
          justify-content: space-between;

          .category {
            ${font('text', 'xs', '600')}
            align-items: center;
            color: #6f7283;
            padding: 7px 5px 4px 5px;
            background: #f0f1f7;
            border-radius: 2px;
          }

          .name {
            ${font('display', 'sm', '700')};
            color: ${colors.gray[900]};
            margin-bottom: 12px;

            &:has(+ .subtitle) {
              margin-bottom: 2px;
            }
          }

          .subtitle {
            ${font('text', 'sm', '700')}
            color: ${colors.gray[500]};
            margin-bottom: 12px;
          }

          .description {
            ${font('text', 'sm', '400')};
            color: ${colors.gray[700]};
          }

          .footer {
            display: flex;
            justify-content: space-between;
            gap: 16px;
          }

          .ext-link {
            font-size: 14.4px;
            line-height: 150%;
          }

          .link {
            ${font('text', 'xs', '400')};
          }
        }
      }
    }
  }
`;
